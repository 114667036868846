import * as React from "react";
import { HeadFC, Link, navigate } from "gatsby";
import { CustomHead } from "../../components/shell/CustomHead";
import SignInForm from "../../components/forms/SignInForm";
import { useFirebaseService } from "../../core/contexts/firebase";
import { Id, toast, TypeOptions } from "react-toastify";
import { FirebaseError } from "firebase/app";
import { FirebaseAuthErrorCode } from "../../core/services/firebase";
import { Tab } from "@headlessui/react";
import EmailForm from "../../components/forms/EmailForm";
import { EnvelopeIcon, PencilIcon } from "@heroicons/react/24/outline";
import {
  getMultiFactorResolver,
  MultiFactorInfo,
  MultiFactorResolver,
  MultiFactorSession,
} from "firebase/auth";
import CheckField from "../../components/fields/CheckField";
import VerifyPhoneNumberForm from "../../components/forms/VerifyPhoneNumberForm";
import { useShowReCaptcha } from "../../core/hooks/useShowRecaptcha";
import { useRedirectUrl } from "../../core/hooks/useRedirectUrl";
import appConfig from "../../core/utils/config";
import Shell from "../../components/shell/Shell";

const pageDescription =
  "Sign in to access all our features meant to make your financing experience with us that much better.";

const SignInPage = ({ location }: any) => {
  const [recaptchaState] = useShowReCaptcha();
  const [redirectUrl, redirectSearch] = useRedirectUrl();
  const firebaseService = useFirebaseService();
  const [verificationId, setVerificationId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState("");
  const [mfaDisplayName, setMfaDisplayName] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(
    location.state?.skipSearch ? 1 : 0
  );
  const [email, setEmail] = React.useState(location.state?.email ?? "");
  const [password, setPassword] = React.useState("");
  const [selectedHint, setSelectedHint] =
    React.useState<MultiFactorInfo | null>(null);
  const isMagicLinkSent = location.hash === "#sent";
  const mfaResolverRef = React.useRef<MultiFactorResolver | null>(null);

  // Setup MFA
  const { enableTwoFactorAuth } = appConfig;
  const mfsRef = React.useRef<MultiFactorSession | null>(null);
  React.useEffect(() => {
    setIsLoading(true);
    if (selectedHint && firebaseService) {
      if (mfsRef.current == null) {
        updateMultiFactorSession().catch(() => setIsLoading(false));
      }
    }
  }, [selectedHint, firebaseService, mfsRef.current]);

  const getRedirectUrl = () => {
    let url = "/";
    if (redirectUrl) {
      url = redirectUrl;
      if (redirectSearch) {
        url = `${url}${redirectSearch}`;
      }
    }
    return url;
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);
  const onSearchForAccount = async (isValid: boolean) => {
    if (isValid) {
      // check if we already displayed a toast
      if (toastId.current === null) {
        toastId.current = toast("Searching database for account", {
          autoClose: false,
        });
      } else {
        toast.update(toastId.current, { autoClose: false });
      }
      try {
        const result = await firebaseService?.doVerifyIsNewUser(email);
        if (result && result.length > 0) {
          // An account was found for the user, redirect user to sign in
          const message = "An account was found for the user, please continue";
          makeToast(message, toast.TYPE.SUCCESS);
          setSelectedIndex(selectedIndex + 1);
        } else {
          // An account was not found for the user, continue with sign up
          const message =
            "An account was not found for the user, please sign up";
          makeToast(message, toast.TYPE.INFO);
          navigate(`/auth/signup${location.search}`, {
            state: { email, skipSearch: true },
          });
        }
        toastId.current = null;
      } catch (error) {
        console.log(error);
        const message = "Something went wrong while checking";
        makeToast(message, toast.TYPE.ERROR);
        toastId.current = null;
      }
    }
  };

  const updateMultiFactorSession = async (): Promise<void> => {
    try {
      if (firebaseService) {
        const mfs = await firebaseService.doGetMultiFactorSession();
        mfsRef.current = mfs;
      }
    } catch (error) {
      console.error("unable to update multifactor session: " + error);
    }
  };

  const onSignIn = async (isValid: boolean) => {
    if (isValid && firebaseService) {
      makeToast("Authenticating your account", toast.TYPE.INFO, false);
      try {
        const credential = await firebaseService.doSignInWithEmailAndPassword(
          email,
          password
        );
        if (credential) {
          // An account was authenticated for the user
          const message =
            "Account was authenticated successfully. Redirecting you to The Dashboard.";
          makeToast(message, toast.TYPE.SUCCESS);

          // Send user to the dashboard
          navigate(getRedirectUrl());
        } else {
          // An account was not created for the user
          const message =
            "Unable to authenticate the account, please try again";
          makeToast(message, toast.TYPE.ERROR, false);
        }
        toastId.current = null;
      } catch (error) {
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to sign in. Account disabled. Please contact support.";
          } else if (error.code === "auth/multi-factor-auth-required") {
            if (!enableTwoFactorAuth) {
              message =
                "Unable to sign in. Account requires two-factor authentication in a disabled environment. Please contact support.";
            } else {
              // The user is a multi-factor user. Second factor challenge is required.
              mfaResolverRef.current = getMultiFactorResolver(
                firebaseService.auth,
                error as any
              );
              message =
                "Account was authenticated successfully. Redirecting you to multi-factor authentication.";
              makeToast(message, toast.TYPE.SUCCESS);
              toastId.current = null;
              setSelectedHint(mfaResolverRef.current.hints[0]);
              setSelectedIndex(selectedIndex + 1);
              return;
            }
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onSignInWithGoogle = async () => {
    if (firebaseService) {
      makeToast(
        "Authenticating your account with Google",
        toast.TYPE.INFO,
        false
      );
      try {
        const credential = await firebaseService.doSignInWithGoogle();
        if (credential) {
          // An account was authenticated for the user
          const message =
            "Account was authenticated successfully. Redirecting you to The Dashboard.";
          makeToast(message, toast.TYPE.SUCCESS);

          // Send user to the dashboard
          navigate(getRedirectUrl());
        } else {
          // An account was not created for the user
          const message =
            "Unable to authenticate the account, please try again";
          makeToast(message, toast.TYPE.ERROR, false);
        }
        toastId.current = null;
      } catch (error) {
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to sign in. Account disabled. Please contact support.";
          } else if (error.code === "auth/multi-factor-auth-required") {
            // The user is a multi-factor user. Second factor challenge is required.
            mfaResolverRef.current = getMultiFactorResolver(
              firebaseService.auth,
              error as any
            );
            message =
              "Account was authenticated successfully. Redirecting you to multi-factor authentication.";
            makeToast(message, toast.TYPE.SUCCESS);
            toastId.current = null;
            setSelectedHint(mfaResolverRef.current.hints[0]);
            setSelectedIndex(selectedIndex + 1);
            return;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };
  const onSignInWithEmailLink = async () => {
    if (firebaseService) {
      makeToast("Sending a magic link to your inbox", toast.TYPE.INFO, false);
      try {
        await firebaseService.doSendSignInLinkToEmail(email, getRedirectUrl());
        // An account was authenticated for the user
        const message =
          "Magic link was sent successfully. Please check your inbox for sign in instructions.";
        makeToast(message, toast.TYPE.SUCCESS);
        navigate(`${location.search}#sent`);
        toastId.current = null;
      } catch (error) {
        let message =
          "Something went wrong while sending your magic link, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to sign in. Account disabled. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };
  const onVerifyCode = async (isValid: boolean) => {
    if (isValid && firebaseService && mfaResolverRef.current) {
      setIsLoading(true);
      try {
        let message = "Verifying your code.";
        makeToast(message, toast.TYPE.INFO);
        const assertion = await firebaseService.doVerifyPhoneNumberCode(
          verificationId,
          verificationCode
        );
        await mfaResolverRef.current.resolveSignIn(assertion);
        message = "Code verified successfully.";
        makeToast(message, toast.TYPE.SUCCESS);
        setIsLoading(false);
        toastId.current = null;
        navigate(getRedirectUrl());
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        let message =
          "Something went wrong while verifying your code, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to verify code. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to verify code. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to verify code. Account disabled. Please contact support.";
          } else if (error.code === "auth/invalid-phone-number") {
            message =
              "Unable to verify code. Account phone number is invalid. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onResendCode = async () => {
    if (mfaResolverRef.current && recaptchaState.ref) {
      setIsLoading(true);
      try {
        const message = "Sending verification code to your phone number";
        makeToast(message, toast.TYPE.INFO);
        setVerificationId("");
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          multiFactorHint: selectedHint!,
          session: mfaResolverRef.current.session,
        };
        const verificationId =
          await firebaseService?.doSendPhoneNumberVerification(
            phoneInfoOptions,
            recaptchaState.ref
          );
        if (verificationId) {
          setVerificationId(verificationId);
          if (selectedIndex === 2) {
            setSelectedIndex(selectedIndex + 1);
          }
        } else {
          const message = "Sending verification code to phone number failed";
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        let message =
          "Something went wrong while sending verification code to your phone number, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };
  const getCurrentTitle = () => {
    if (isMagicLinkSent) {
      return "Check your inbox";
    }

    switch (selectedIndex) {
      case 0:
        return "Search for your account";
      case 1:
        return "Gain access to your account";
      case 2:
        return "Verify access to your account";
      case 3:
        return "Verify access to your account";
      default:
        return "Sign in";
    }
  };

  const getCurrentDescription = () => {
    if (isMagicLinkSent) {
      return (
        <p>
          A magic link was sent to <strong>{email}</strong>. Check your inbox
          and sign in with the magic link.
        </p>
      );
    }

    switch (selectedIndex) {
      case 0:
        return (
          <p>
            We might not know you yet. Validate your email before you continue.
          </p>
        );
      case 1:
        return (
          <p>
            Access your account and the dashboard. Simply sign in with your
            Google account or using your email and password. Alternatively, send
            a magic link to your email inbox <strong>({email})</strong> to sign
            in without a password.{" "}
          </p>
        );
      case 2:
        return (
          <p>
            Verify access to your account and the dashboard. Before you
            continue, please choose one of the available authentication factors
            below that you would like to verify yourself by.
          </p>
        );
      case 3:
        return (
          <p>
            Verify access to your account and the dashboard. Verification code
            sent successfully. Please enter the 6-digit code sent to your phone
            number.
          </p>
        );
      default:
        return "Sign up";
    }
  };

  return (
    <Shell>
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-4 lg:grid-cols-5">
            <div className="col-span-1 flex flex-1 flex-col justify-center pr-0 md:col-span-2 lg:col-span-3 lg:flex-none lg:pr-12 xl:pr-16">
              <div className="w-full max-w-3xl">
                <div>
                  <div className="mb-16 flex justify-between">
                    <p className="text-sm">
                      <span>Not a member yet?</span>{" "}
                      <Link
                        to={`/auth/signup${location.search}`}
                        state={{ email }}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Sign Up
                      </Link>
                    </p>
                    <p
                      className={`text-sm transition-opacity duration-300 ${
                        isMagicLinkSent
                          ? "opacity-0"
                          : selectedIndex === 1
                          ? "opacity-1"
                          : "opacity-0"
                      }`}
                    >
                      <button
                        onClick={() => setSelectedIndex(selectedIndex - 1)}
                        disabled={selectedIndex === 0 || isMagicLinkSent}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        &larr;&nbsp;Go to previous
                      </button>
                    </p>
                  </div>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    {getCurrentTitle()}
                  </h2>
                  <p className="mt-4 text-base font-medium text-gray-600">
                    {getCurrentDescription()}
                  </p>
                </div>

                <div className="mt-8">
                  {isMagicLinkSent && (
                    <div className="mt-24">
                      <p>
                        Did not receive the email? Check your spam filter, or{" "}
                        <Link
                          className="nline-flex items-center text-base font-medium leading-4 text-indigo-600 hover:text-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          to={`/auth/signin?redirectUrl=${location.pathname}&redirectSearch=${location.search}`}
                          replace
                        >
                          try another email address
                        </Link>
                        .
                      </p>
                    </div>
                  )}
                  {!isMagicLinkSent && (
                    <Tab.Group selectedIndex={selectedIndex}>
                      <Tab.List className="hidden">
                        <Tab>Tab 1</Tab>
                        <Tab>Tab 2</Tab>
                        <Tab>Tab 3</Tab>
                        <Tab>Tab 4</Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel tabIndex={0}>
                          <EmailForm
                            label="Search"
                            email={email}
                            onEmailChanged={setEmail}
                            onButtonClick={onSearchForAccount}
                          />
                        </Tab.Panel>
                        <Tab.Panel tabIndex={1}>
                          <div>
                            <div>
                              <p className="text-sm font-medium text-gray-700">
                                Sign in with
                              </p>

                              <div className="mt-1 grid grid-cols-2 gap-3">
                                <button
                                  onClick={onSignInWithEmailLink}
                                  className="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                                >
                                  <EnvelopeIcon className="mr-2 h-5 w-5" />
                                  Magic Link
                                </button>
                                <div>
                                  <button
                                    onClick={onSignInWithGoogle}
                                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                                  >
                                    <span className="sr-only">
                                      Sign in with Google
                                    </span>
                                    <svg
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 48 48"
                                    >
                                      <defs>
                                        <path
                                          id="a"
                                          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                                        />
                                      </defs>
                                      <clipPath id="b">
                                        <use
                                          xlinkHref="#a"
                                          overflow="visible"
                                        />
                                      </clipPath>
                                      <path
                                        clipPath="url(#b)"
                                        fill="#FBBC05"
                                        d="M0 37V11l17 13z"
                                      />
                                      <path
                                        clipPath="url(#b)"
                                        fill="#EA4335"
                                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                                      />
                                      <path
                                        clipPath="url(#b)"
                                        fill="#34A853"
                                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                                      />
                                      <path
                                        clipPath="url(#b)"
                                        fill="#4285F4"
                                        d="M48 48L17 24l-4-3 35-10z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="relative mt-6">
                              <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true"
                              >
                                <div className="w-full border-t border-gray-300" />
                              </div>
                              <div className="relative flex justify-center text-sm">
                                <span className="bg-gray-100 px-2 text-gray-500">
                                  Or continue with
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-6">
                            <SignInForm
                              email={email}
                              password={password}
                              trailingEmail={
                                <button
                                  onClick={() =>
                                    setSelectedIndex(selectedIndex - 1)
                                  }
                                  type="button"
                                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                  <PencilIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span>Change</span>
                                </button>
                              }
                              disabledEmail
                              onEmailChange={setEmail}
                              onPasswordChange={setPassword}
                              onButtonClick={onSignIn}
                            />
                          </div>
                        </Tab.Panel>
                        <Tab.Panel tabIndex={2}>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (selectedHint !== null) {
                                onResendCode();
                              }
                            }}
                            className="space-y-6"
                          >
                            {mfaResolverRef.current?.hints.map((hint) => {
                              const phoneNumber = (hint as any).phoneNumber;
                              const displayName = hint.displayName;
                              const sPhoneNumber = (selectedHint as any)
                                ?.phoneNumber;
                              const sDisplayName = selectedHint?.displayName;
                              const isSelected =
                                phoneNumber === sPhoneNumber &&
                                displayName === sDisplayName;
                              return (
                                <CheckField
                                  key={hint.enrollmentTime}
                                  id={hint.enrollmentTime}
                                  checked={isSelected}
                                  description={
                                    displayName
                                      ? `${displayName} - ${phoneNumber}`
                                      : phoneNumber
                                  }
                                  onChange={(checked) => setSelectedHint(hint)}
                                />
                              );
                            })}
                            <button
                              type="submit"
                              className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Send Code
                            </button>
                          </form>
                        </Tab.Panel>
                        <Tab.Panel tabIndex={3}>
                          <VerifyPhoneNumberForm
                            label="Verify Code"
                            mfaDisplayName={mfaDisplayName}
                            onMFADisplayNameChange={setMfaDisplayName}
                            onCodeChange={setVerificationCode}
                            onButtonClick={onVerifyCode}
                            onResendCodeClick={onResendCode}
                          />
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  )}
                </div>
              </div>
            </div>
            <div className="relative col-span-1 block h-full w-full flex-1 overflow-hidden rounded-3xl md:col-span-2 lg:col-span-2">
              <img
                className="absolute inset-0 top-0 left-0 right-0 h-full w-full rounded-3xl object-cover shadow-sm"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
              />
              <div
                className="absolute inset-0 rounded-md bg-indigo-800 mix-blend-multiply"
                aria-hidden="true"
              ></div>
              <p className="relative py-12 px-4 md:px-6 lg:px-8">
                <p className="text-3xl font-bold leading-[40px] text-indigo-100">
                  Access the Dashboard
                </p>
                <p className="mt-4 text-xl font-light text-indigo-200">
                  Discover a new and easy way of managing your finances.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default SignInPage;

export const Head: HeadFC = () => (
  <CustomHead title="Sign In" description={pageDescription} />
);
